function cartNotification(e) {
    e.preventDefault();

    var btn = $(this);
    var qty = btn.siblings('input').val();

    var header = $('.site-header');
    var note = $('<div class="header__cart-notification header__collapse-content" style="display: none;"><p>Added to cart. When you are ready to complete your order, just click on the cart icon to proceed to checkout.</p></div>');

    var cartNotBox = $('.header__cart-notification');


    if(cartNotBox.length > 0) {

    }
    else {
        if(qty > 0) {
            header.append(note);
            var cartNotBox = $('.header__cart-notification');
            cartNotBox.slideToggle();
            setTimeout(function() {cartNotBox.slideUp(function() {
                cartNotBox.remove();
            }) }, 5000);
        }
    }
}
$('.change-cart').click(cartNotification);
