function headerCollapseContents() {

    var collapseBtn = $('.header-right-content [data-toggle="collapse"]');
    var mobileMenuBtn = $('.site-header .js-mobilemenu-btn');

    var collapseContents = $('.site-header > .collapse');

    collapseBtn.click(function() {
        var collapseContent = $($(this).attr('href'));
        collapseContents.not(collapseContent).each(function() {
            var otherCollapseContent = $(this);
            if(otherCollapseContent.hasClass('in')) {
                var id = $(this).attr('id');
                var btn = $('.header-right-content [href="#' + id + '"]');
                btn.trigger('click');
            }
        });

        if(mobileMenuBtn.hasClass('opened')) {
            mobileMenuBtn.trigger('click');
        }

    });

    mobileMenuBtn.click(function() {
        collapseContents.each(function() {
            var collapseContent = $(this);
            if(collapseContent.hasClass('in')) {
                var id = $(this).attr('id');
                var btn = $('.header-right-content [href="#' + id + '"]');

                btn.trigger('click');
            }
        });
    });

}
headerCollapseContents();
