function dropdownTab() {

    var tabs = $('.js-multiple-tab');


    tabs.each(function() {
        var tab = $(this);

        if(tab.length > 0) {

            var dataTab = $(this).attr('data-tab');
            var multipleTabs = $('[data-tab="'+ dataTab +'"]');
            var tabLinks = multipleTabs.find('[data-toggle="tab"]');

            $('[data-tab="'+ dataTab +'"].dropdown-menu').each(function() {
                var activeTabText = $(this).find('.active a').text();
                var attr = $(this).attr('aria-labelledby');
                var dropdownBtn = $('button#' + attr);
                if(dropdownBtn.length > 0) {
                    var btnText = dropdownBtn.find('span');
                    btnText.text(activeTabText);
                }
            });

            tabLinks.click(function (e) {
                //get selected href
                var href = $(this).attr('href');

                //set all nav tabs to inactive
                multipleTabs.find('li').removeClass('active');

                //get all nav tabs matching the href and set to active
                multipleTabs.find('li a[href="'+href+'"]').closest('li').addClass('active');

                var activeTabText = $(this).find('.active a').text();

                $('[data-tab="'+ dataTab +'"].dropdown-menu').each(function() {
                    var activeTabText = $(this).find('.active a').text();
                    var attr = $(this).attr('aria-labelledby');
                    var dropdownBtn = $('button#' + attr);
                    if(dropdownBtn.length > 0) {
                        var btnText = dropdownBtn.find('span');
                        btnText.text(activeTabText);
                    }
                });

                //active tab
                $('#'+ dataTab +' .tab-pane').removeClass('active');
                $('#'+ dataTab +' .tab-pane'+href).addClass('active');
            });

        }
    });

}
dropdownTab();
