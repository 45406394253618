function slickSlider() {

    var sliders = $('.js-slick-slider');

    sliders.each(function() {
        var slider = $(this);

        if(slider.length > 0) {
            slider.slick({
                dots: true,
                arrows: false
            });
        }
    });


}
slickSlider();

