/**
* Detect the user browser version
 */
function detectBrowser() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

/**
 * Helper function for easier html insert
 * @param htmlStr
 * @returns {DocumentFragment}
 */
function create(htmlStr) {
    var frag = document.createDocumentFragment(),
        temp = document.createElement('div');
    temp.innerHTML = htmlStr;
    while (temp.firstChild) {
        frag.appendChild(temp.firstChild);
    }
    return frag;
}

/**
 * Helper prototype for vanilla js to remove html from dom
 */

Element.prototype.remove = function() {
    this.parentElement.removeChild(this);
}
NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
    for(var i = this.length - 1; i >= 0; i--) {
        if(this[i] && this[i].parentElement) {
            this[i].parentElement.removeChild(this[i]);
        }
    }
}

// Get IE or Edge browser version
var version = detectBrowser();

if (version >= 12) {
    // Insert fader div after the body
    var fader = create('<div id="preloader" style="background-color:#fff; position:fixed; width:100%; height:100%; top:0px; left:0px; z-index:1000;"></div>');
    document.getElementById("body").appendChild(fader);
}

if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    window.onload = function () {
        document.getElementById("preloader").remove();
    }
}