function toggleBtnClass() {
    var btn = $(this);
    var attr = btn.attr('href');
    var target = $(btn.attr('href'));

    if(target.length > 0) {
        if(target.hasClass('in')) {
            $('.link--toggle[href="' + attr + '"]').attr('aria-expanded', 'false');
            $('.link--toggle[href="' + attr + '"]').addClass('collapsed');

        }
        else {
            $('.link--toggle[href="' + attr + '"]').attr('aria-expanded', 'true');
            $('.link--toggle[href="' + attr + '"]').removeClass('collapsed');
        }
    }

}
$('.link--toggle').click(toggleBtnClass);
