function scrollToContent(e) {
    var headerHeight = $('.site-header').height();
    e.preventDefault();
    var btn = $(this);
    var target = $(btn.attr('href'));

    if(target.length > 0) {
        $("html, body").animate({ scrollTop: target.offset().top - headerHeight }, 1000);
    }

}
$('.js-scroll-to-content').click(scrollToContent);
