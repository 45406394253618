function setMinHeight() {
    var footerHeight = $('.site-footer').outerHeight();
    var content = $('.site-wrapper');

    var height = $(window).height() - footerHeight;

    content.css({
        'min-height': height
    });
}
setMinHeight();

$(window).resize(function() {
    setMinHeight();
})
