function customSelect() {

    $(document).ready(function() {
        var selects = $('.js-selectboxit');

        if(selects.length > 0) {
            selects.each(function() {
                var select = $(this);

                // Options for custom Select
                jcf.setOptions('Select', {
                    wrapNative: false,
                    wrapNativeOnMobile: false,
                    fakeDropInBody: false,
                    maxVisibleItems: 10
                });
                jcf.replace(select);
            });

            /*select.selectBoxIt({
             downArrowIcon: "icon-arrowdown",
             autoWidth: false
             });*/
        }


    });

}

customSelect();





