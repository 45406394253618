function mobileMenu() {
    var btn = $(this);
    var menu = $(btn.attr('data-target'));

    btn.toggleClass('opened');

    if(menu.length > 0) {
        menu.slideToggle();
    }
}
$('.js-mobilemenu-btn').click(mobileMenu);
