function collapseTriangle() {


    var btns = $('.link--toggle');

    btns.each(function() {
        var btn = $(this);

        var attr = btn.attr('href');
        var btnWrapper = $('.link--toggle[href="' + attr + '"]').not('.link--toggle-close').closest('.box__buttons');
        var target = $(btn.attr('href'));

        if(target.length > 0) {
            if(target.hasClass('in')) {
                if(btnWrapper.length > 0) {
                    btnWrapper.addClass('box__buttons--has-triangle');
                }
            }
            else {
                if(btnWrapper.length > 0) {
                    btnWrapper.removeClass('box__buttons--has-triangle');
                }
            }
        }

        btn.click(function() {
            if(target.length > 0) {
                if(target.hasClass('in')) {
                    if(btnWrapper.length > 0) {
                        btnWrapper.removeClass('box__buttons--has-triangle');
                    }
                }
                else {
                    if(btnWrapper.length > 0) {
                        btnWrapper.addClass('box__buttons--has-triangle');
                    }
                }
            }
        });
    })


}
collapseTriangle();
