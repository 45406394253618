function addNewForm() {
  if ($('.js-add-new-form').length) {
    $('.js-add-new-form').each(function() {
      var radios = $(this).find('input[type="radio"]');
      var buttonAddnew = $(this).find('.address-list--addnew');
      var form = $($(this).data('target'))

      if (radios.filter(':checked').data('show-form')) {
        form.show();
      }

      radios.on('change', function() {
        var inputs = form.find('input');
        if ($(this).data('show-form')) {
          form.show();
          inputs.each(function() {
            if($(this).data('required')) {
              $(this).attr("required", true);
            }
          })
        }
        else {
          form.hide();
          inputs.removeAttr('required');
        }
      })

      buttonAddnew.click(function() {
        var billingInputs = form.find('input');
        form.show();
        billingInputs.each(function() {
          if($(this).data('required')) {
              $(this).attr("required", true);
          }
        })
        form.find('button[type="cancel"]').click(function(){
            billingInputs.removeAttr('required');
            form.hide();
        })
      })
    })

  }
}

addNewForm();
